import { Injectable } from "@angular/core";
import { ScanCode } from "@core/models";

@Injectable()
export class ScanCodeService {
  private readonly SCAN_CODE_KEY = 'scan-code-key';

  set scanCode(code: ScanCode) {
    sessionStorage.setItem(this.SCAN_CODE_KEY, JSON.stringify(code));
  }

  get scanCode(): ScanCode | null {
    if (sessionStorage.getItem(this.SCAN_CODE_KEY)) {
      return JSON.parse(sessionStorage.getItem(this.SCAN_CODE_KEY));
    }

    return null;
  }
}
